<template>
  <div class="container">
    <b-row class="vh-100" align-v="center">
      <b-col cols="12" sm="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="6" offset-xl="3">
        <b-card class="card-login shadow rounded mx-auto">
          <b-card-body class="flex flex-col items-center">
            <img class="login-logo" src="@/assets/logo-banner-green.png" />
            <Alert v-if="nameFromUrl && !errorMessage && !success" variant="purple">
              Hi, {{ firstName }} {{ lastName }}! <br />Please create a password for your new account.
            </Alert>

            <Alert v-if="errorMessage" variant="red" icon="exclamation">{{ errorMessage }}</Alert>

            <Alert v-if="success" variant="green" icon="check" class="mb-8">
              Your account was successfully created.
            </Alert>

            <b-form v-else class="invite-form w-100">
              <b-form-group v-show="!nameFromUrl" :invalid-feedback="veeErrors.first('firstName')">
                <label for="org-name" class="mb-1">First Name <span class="text-danger">*</span></label>
                <b-form-input
                  name="firstName"
                  placeholder="First Name"
                  v-model="firstName"
                  v-validate="{ required: true }"
                  :state="validateState('firstName')"
                  aria-describedby="firstName-feedback"
                  data-vv-as="first name"
                />
              </b-form-group>

              <b-form-group v-show="!nameFromUrl" :invalid-feedback="veeErrors.first('lastName')">
                <label for="org-name" class="mb-1">Last Name <span class="text-danger">*</span></label>
                <b-form-input
                  name="lastName"
                  placeholder="Last Name"
                  v-model="lastName"
                  v-validate="{ required: true }"
                  :state="validateState('lastName')"
                  aria-describedby="lastName-feedback"
                  data-vv-as="last name"
                />
              </b-form-group>

              <b-form-group :invalid-feedback="veeErrors.first('input-password')">
                <label for="org-name" class="mb-1">Password <span class="text-danger">*</span></label>
                <b-form-input
                  name="input-password"
                  placeholder="Password"
                  type="password"
                  v-model="password"
                  v-validate="{ required: true, password: true }"
                  :state="validateState('input-password')"
                  data-vv-as="password"
                />
              </b-form-group>

              <b-form-group :invalid-feedback="veeErrors.first('input-confirm-password')">
                <label for="org-name" class="mb-1">Confirm Password <span class="text-danger">*</span></label>
                <b-form-input
                  autocomplete="new-password"
                  name="input-confirm-password"
                  placeholder="Confirm Password"
                  type="password"
                  v-model="confirmPassword"
                  v-validate="{ required: true, confirmed: password }"
                  :state="validateState('input-confirm-password')"
                  data-vv-as="password"
                />
              </b-form-group>
            </b-form>

            <BaseButton v-if="success" size="lg" variant="success" @click.native="pushToLogin"> Login </BaseButton>
            <BaseButton v-else variant="success" size="lg" :loading="formSubmitting" @click.native="submit">
              Create Account
            </BaseButton>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UserServiceV2 from '@/lib/user-service-v2';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

export default {
  components: {
    Alert,
    BaseButton
  },
  data() {
    return {
      confirmPassword: '',
      password: '',
      firstName: '',
      lastName: '',
      errorMessage: '',
      success: false,
      formSubmitting: false,
      nameFromUrl: false
    };
  },
  mounted() {
    // Grab First and Last name if in url and populate/hide fields
    const firstName = this.$route.query.first_name;
    const lastName = this.$route.query.last_name;

    if (firstName && lastName) {
      this.firstName = firstName;
      this.lastName = lastName;
      this.nameFromUrl = true;
    }
  },
  metaInfo: {
    title: 'Sign-up for Rafflebox'
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    pushToLogin() {
      this.$router.push('/login');
    },
    async submit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      this.formSubmitting = true;

      try {
        const emailToken = this.$route.query.emailToken;
        const passwordToken = this.$route.query.passwordToken;

        const completeInvite = {
          firstName: this.firstName,
          lastName: this.lastName,
          emailToken,
          passwordToken,
          password: this.password
        };

        await UserServiceV2.completeInvite(completeInvite);
        this.success = true;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'Invite failed. Please contact support.';
        }
      } finally {
        this.formSubmitting = false;
      }
    }
  }
};
</script>

<style>
.invite-form .invalid-feedback {
  position: absolute;
  bottom: 0.5rem;
  padding-left: 0.75rem;
}
</style>

<style scoped>
.container {
  margin: auto;
}

.login-logo {
  max-width: 100%;
  max-height: 100%;
  max-width: 80%;
  padding-bottom: 1.5rem;
}

.app-title {
  padding-bottom: 2rem;
}

.invite-form {
  text-align: left;
}

.invite-form .form-group {
  position: relative;
  margin-bottom: 0;
}

.invite-form .form-control {
  margin-bottom: 2rem;
  height: calc(2.5em + 0.75em + 2px);
  border-radius: 0.5rem;
}

.card-body {
  text-align: center;
  padding: 1.25rem;
}

.card {
  padding: 1.5rem;
}

.submit-button {
  margin-top: 0.5rem;
}
</style>
